import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoosterChoiceDialogComponent } from './rooster-choice-dialog.component';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
  declarations: [RoosterChoiceDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule
  ]
})
export class RoosterChoiceDialogModule { }
