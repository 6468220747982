<mat-card class="add-caregroup-card mat-elevation-z">
  <mat-card-header>
    <mat-card-title *ngIf="data.title">{{data.title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-dialog-content class="container">
<!--      <p *ngIf="data.message">{{data.message}}</p>-->
<!--      <p *ngIf="!data.message">{{data}}</p>-->

      <div class="buttons">
        <button *ngFor="let icon of icons"
                [ngStyle]="{'backgroundColor': icon.color, 'cursor': 'pointer' }"
                (click)="icon.action()">
          <mat-icon>{{ icon.matIcon }}</mat-icon>
          {{ icon.title }}
        </button>
      </div>
    </mat-dialog-content>
  </mat-card-content>
</mat-card>

