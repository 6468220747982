export const environment = {
  production: true,
  skipAuthentication: false,
  apiUrl: 'https://api-meditouch.vqtech.nl/v1',
  authClientId: 'xxxx',
  authAuthority: 'xxxx',
  authRedirectUri: 'https://mijn.medivus.nl/',
  authLogoutRedirectUri: 'https://mijn.medivus.nl/',
  authApiScope: 'api://ea96ae12-4e32-49ca-916e-264387d5a7d6/General',
  appEnv: 'development',
  medicatiefUrl: 'https://medicatief.vqtech.nl',
  publicURLPaths: ['/zakelijk', '/onboarding']
};
