<mat-card class="add-caregroup-card mat-elevation-z">
  <mat-card-header>
    <mat-card-title *ngIf="data.title">{{data.title}}</mat-card-title>
    <mat-card-title *ngIf="!data.title">Weet u het zeker?</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-dialog-content>
      <p *ngIf="data.message">{{data.message}}</p>
      <p *ngIf="!data.message">{{data}}</p>

      <div class="buttons">
        <button mat-button (click)="close()">{{data.cancelText}}</button>
        <button mat-raised-button (click)="confirm()" style="background-color: {{color}}" [color]="data.confirmText === 'Verwijderen' ? 'warn' : 'primary'">{{data.confirmText}}</button>
      </div>
    </mat-dialog-content>
  </mat-card-content>
</mat-card>

