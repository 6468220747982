import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {environment} from "../environments/environment";

const isPublicPath = (): boolean => {
  for (const path of environment.publicURLPaths) {
    if (window.location.href.includes(path)) {
      return true;
    }
  }
  return false;
}

const guards: any[] = (environment.skipAuthentication || isPublicPath()) ? [] : [MsalGuard];
const routes: Routes = [
  {
    canActivate: guards,
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
  }, {
    canActivate: [],
    path: 'zakelijk',
    loadChildren: () => import('./modules/external/external.module').then(m => m.ExternalModule),
  }, {
    canActivate: [],
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
