import { Injectable } from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";

@Injectable()
export class SidebarService {
  private sideBar: MatSidenav;

  public setSideBar(sideBar: MatSidenav) {
    this.sideBar = sideBar;
  }

  public open() {
    return this.sideBar.open();
  }


  public close() {
    return this.sideBar.close();
  }

  public toggle(): void {
    this.sideBar.toggle();
  }
}
