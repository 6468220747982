import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from "src/environments/environment";
import * as moment from 'moment';
import {
  AddProfileModule,
  Assignment,
  Client, ClientInternshipRequest,
  CreateClientInternshipRequest, CreateStudentAssignmentSubmission, DocUpload,
  InternshipCompany, MissingContactPerson,
  NestedClientStudyTrack,
  PaginatedResult, StudentAssignmentSubmission, StudentModules, StudentProgress, StudentStudyComponentSpec, StudyModule,
  StudyResult, TimeRegistration, TimeRegistrationType
} from '../../../models';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {APIFilterOption} from '../../base-rest-api/base-rest-api.service';
moment.locale('nl');

@Injectable({providedIn: 'root'})
export class StudentService {
  private _selectedClientStudyTrack;

  setSelectedStudyTrack(studyTrack: NestedClientStudyTrack) {
    this._selectedClientStudyTrack = studyTrack;
    sessionStorage.setItem('selectedTrackId', studyTrack.id.toString());
    this.selectedStudyTrackSubject.next(this._selectedClientStudyTrack);
  }

  getSelectedStudyTrack(): Observable<NestedClientStudyTrack> {
    return this.selectedStudyTrackSubject.asObservable();
  }

  selectedStudyTrackSubject = new BehaviorSubject<NestedClientStudyTrack>(null);

  constructor(private http: HttpClient) {}

  public getSuperSaasLink(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/student/my/supersaas`, {responseType: 'text'});
  }

  public getAllModulesForStudentWithId(): Observable<StudentModules> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/modules?groupBy=components`);
  }

  public getMyProgress(): Observable<StudentProgress> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/progress`);
  }

  public getClient(): Observable<Client> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/client`);
  }

  public getAllResults(pageIndex = 1, pageSize = 10): Observable<PaginatedResult<StudyResult>> {
    return this.http.get<PaginatedResult<StudyResult>>(`${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/results?page=${pageIndex}&limit=${pageSize}`).pipe((map(response => {
      response.items = response.items.map(plain => plainToClass(StudyResult, plain));
      return response;
    })));
  }

  public requestInternship(dto: CreateClientInternshipRequest): Observable<ClientInternshipRequest> {
    return this.http.post<ClientInternshipRequest>(
        `${environment.apiUrl}/student/my/internship/request`,
        dto
    );
  }

  public getInternshipRequests(): Observable<ClientInternshipRequest[]> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/internship/request`);
  }

  public findInternshipCompanies(searchText: string, typeId: number): Observable<PaginatedResult<InternshipCompany>> {
    const filterOptions = {};
    filterOptions['search'] = encodeURIComponent(searchText);
    filterOptions['typeId'] = typeId;
    return this.http.get<PaginatedResult<InternshipCompany>>(`${environment.apiUrl}/student/my/find-company?filter=${JSON.stringify(filterOptions)}`);
  }

  public getInternshipCompany(studyTrackId: number, internshipCompanyId: number): Observable<InternshipCompany> {
    return this.http.get<InternshipCompany>(`${environment.apiUrl}/student/my/track/${studyTrackId}/internship-company/${internshipCompanyId}`);
  }

  public getChoosableChoiceCourses(): Observable<StudyModule[]> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/choosable-choice-courses`);
  }

  public uploadPok(internshipRequestId: number, doc: DocUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', doc.file, doc.name);
    formData.append('name', doc.name);
    formData.append('type', doc.type);
    return this.http.post<FormData>(`${environment.apiUrl}/student/my/internship/request/${internshipRequestId}/pok`, formData);
  }

  public getComponent(componentId: number): Observable<StudentStudyComponentSpec> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/components/${componentId}`);
  }

  public submitAssignment(dto: CreateStudentAssignmentSubmission): Observable<StudentAssignmentSubmission> {
    return this.http.post<StudentAssignmentSubmission>(
        `${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/assignments`,
        dto
    );
  }

  public uploadAssignmentAttachment(assignmentSubmissionId: number, doc: DocUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', doc.file, doc.name);
    formData.append('name', doc.name);
    formData.append('type', doc.type);
    return this.http.post<FormData>(`${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/assignments/${assignmentSubmissionId}`, formData);
  }

  uploadToTopic(topicId: number, doc: DocUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', doc.file, doc.name);
    formData.append('name', doc.name);
    formData.append('type', doc.type);
    return this.http.post<FormData>(`${environment.apiUrl}/topic/${topicId}/attachment`, formData);
  }

  public requestGo(): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/go`,
      {}
    );
  }

  public generateCareUpToken(id: number, trackId: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/care-up-token/${trackId}`);
  }

  public addModuleToProfile(dto: AddProfileModule): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/modules`,
      dto
    );
  }

  public removeModuleFromProfile(moduleSpecId): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/student/my/track/${this._selectedClientStudyTrack.id}/modules/${moduleSpecId}`
    );
  }

  public createMissingContactPersonTicket(dto: MissingContactPerson): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/student/my/internship/contact-person/missing`,
      dto
    );
  }

  public getProgressionHours(
    studyTrackId: number,
    dateFrom: string,
    dateTo: string,
    pageIndex = 1, pageSize = 10
  ): Observable<PaginatedResult<TimeRegistration>> {
    return this.http.get<any>(`${environment.apiUrl}/student/my/progression/${studyTrackId}?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${pageIndex}&limit=${pageSize}`);
  }

  public addProgressionHours(data: TimeRegistration[], doc: DocUpload): Observable<any> {
    const formData = new FormData();
    if (doc) {
      formData.append('file', doc.file, doc.name);
      formData.append('doc', JSON.stringify(doc));
    }
    formData.append('data', JSON.stringify(data));

    return this.http.post<FormData>(
      `${environment.apiUrl}/student/my/progression`,
      formData
    );
  }
}
