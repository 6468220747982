import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, Provider} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './core/services/loader/loader.service';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { PaginatorNL_nld } from './util/paginator/paginator-nl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MomentModule } from 'ngx-moment';
import {environment} from '../environments/environment';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {SidebarService} from './layout/sidebar/sidebar.service';
import {DashboardModule} from './modules/dashboard/dashboard.module';
import {FormsModule} from '@angular/forms';
import {CalendarUtils, DateAdapter} from 'angular-calendar';
import {CustomDateAdapter} from './util/date/CustomDateAdapter';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from "@azure/msal-browser";
import {LogLevel} from "msal";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService
} from "@azure/msal-angular";
import {ServiceWorkerModule} from '@angular/service-worker';
import {MockMsalService} from "./core/services/mock/mock-msal.service";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faArrowLeft, faCheck, faSearch} from '@fortawesome/pro-regular-svg-icons';

registerLocaleData(localeNl);

const isPublicPath = (): boolean => {
  for (const path of environment.publicURLPaths) {
    if (window.location.href.includes(path)) {
      return true;
    }
  }
  return false;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authClientId,
      authority: environment.authAuthority,
      redirectUri: environment.authRedirectUri,
      postLogoutRedirectUri: environment.authLogoutRedirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback: console.log,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl + '/*', [environment.authApiScope]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        environment.authApiScope
      ]
    },
    //loginFailedRoute: '/' // Don't have one for this
  };
}

const providers: Provider[] = [{
  provide: MsalService,
  useClass: (environment.skipAuthentication || isPublicPath()) ? MockMsalService : MsalService
}];

const imports = [];
const bootstrap = [];

if (!environment.skipAuthentication && !isPublicPath()) {
  providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      }, {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      MsalGuard,
      MsalBroadcastService
  );

  imports.push(MsalModule);
  bootstrap.push(MsalRedirectComponent);
} else {
  providers.push({
    provide: MSAL_GUARD_CONFIG,
    useFactory: () => {
      return {}
    }
  })
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DashboardModule,
    HttpClientModule,
    ServiceWorkerModule.register('safety-worker.js', {enabled: environment.production}),
    MomentModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSidenavModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ...imports,
    FontAwesomeModule
  ],
  providers: [
    LoaderService,
    CalendarUtils, {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    },
    {
      provide: MatPaginatorIntl,
      useValue: PaginatorNL_nld()
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'nl-NL'
    },
    SidebarService,
    ...providers,
  ],
  bootstrap: [
      AppComponent,
      ...bootstrap
  ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSearch,
      faCheck,
      faArrowLeft
    )
  }
}
