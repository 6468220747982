import {Routes} from '@angular/router';
import {DashboardComponent} from './dashboard.component';

export let ModuleRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      breadcrumb: 'Dashboard'
    }
  }
];
