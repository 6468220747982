<h1>Welkom terug...</h1>

<div class="card">
  <div class="card-body d-flex align-items-center">
      <div class="card-body__avatar d-flex">
        <mat-icon class="d-flex" inline="true" id="avatarIcon">account_circle</mat-icon>
      </div>

    <div class="card-body__info">
      <h5 class="card-title">{{client?.personalDetails?.passportName}}</h5>
      <p class="card-text">{{client?.number}}@medivus.nl</p>
    </div>
  </div>
</div>

<mat-divider class="dashDivider"></mat-divider>

<div class="row quickMenu">
  <h2>Snel aan de slag</h2>

  <ng-container *ngFor="let item of dashboardService.menuItems; let _i = index">
    <div class="quickMenu__items col-12 col-sm-6">
      <div
        (click)="item.routerLink ? null : item.onClick()"
        [routerLink]="item.routerLink ? item.routerLink : null"
        [ngStyle]="{'background-color': item.color}"
        class="d-flex align-items-center quickMenu__item"
      >
        <mat-icon>{{item.matIcon}}</mat-icon>
        {{item.title}}
      </div>
    </div>
  </ng-container>
</div>
