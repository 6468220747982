import {Component, Inject, OnInit} from '@angular/core';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogData} from '../../../../core/models/_template';
import {StudentService} from '../../../../core/services/medivus/student/student.service';

@Component({
  selector: 'app-rooster-choice-dialog',
  templateUrl: './rooster-choice-dialog.component.html',
  styleUrls: ['./rooster-choice-dialog.component.scss']
})
export class RoosterChoiceDialogComponent extends ConfirmationDialogComponent implements OnInit {

  public icons = [
    {title: 'Examens', color: '#6CB8C9', matIcon: 'school', action: (() => this.openExternalUrl('remindo'))},
    {title: 'Toetsen/curussen', color: '#F3A33D', matIcon: 'history_edu', action: (() => this.openExternalUrl('supersaas'))},
  ];

  constructor(
    public dialogRef: MatDialogRef<RoosterChoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private studentService: StudentService
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
  }

  async openExternalUrl(type: string): Promise<void> {
    switch(type) {
      case 'remindo':
        window.open('https://medivus.remindobooking.com/register', '_blank');
        break;
      case 'supersaas':
        window.open(await this.studentService.getSuperSaasLink().toPromise(), '_blank');
        break;
    }
  }
}
