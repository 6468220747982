// DO NOT MAKE CHANGES TO THIS FILE. This file is automatically generated! Add custom models to the _template.ts file!
import {Type} from "class-transformer";
import {MatTableDataSource} from "@angular/material/table";
import * as moment from "moment";

export class PaginatedResult<T> {
  items: T[];
  meta: IPaginationMeta;
  links?: IPaginationLinks
}

export interface IPaginationMeta {
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface IPaginationLinks {
  first?: string;
  previous?: string;
  next?: string;
  last?: string;
}

export interface IOverviewData<T> {
  title: string;
  id: string;
  dataSource: MatTableDataSource<T>;
}

export class DayPartDate {
  date: Date;
  id: PartOfDay;
  name: string;
}

export enum PartOfDay {
  Morning = "morning",
  Afternoon = "afternoon"
}

// -- Constants -- //
export const DefaultPaginationValues = {
  PageSizeOptions: [5, 15, 25],
  PageSize: 5
}

export const DefaultPagination = {
  itemCount: 0,
  totalItems: 0,
  itemsPerPage: DefaultPaginationValues.PageSize,
  totalPages: 1,
  currentPage: 1
} as IPaginationMeta;

export class ButtonData {
  icon: string;
  text: string;
  onClick: Function;
  disabledIf?: (() => boolean) = () => false;

  constructor(args?: Partial<ButtonData>) {
    Object.assign(this, args);
  }
}

// -- Backend errors -- //
export class HttpErrorMessage {
  field: string;
  error: string;
}

export class HttpError {
  statusCode: number;
  message: HttpErrorMessage[] | string[] = [];
  error: string;
}

export class DialogData {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
}

export class ConfirmDialogData extends DialogData {
  deletable?: boolean;
}

export class StudentPanelDialogData extends DialogData {
  hasInput?: boolean;
}

export interface BannerData {
  message: string;
  buttons: Array<{
    text: string;
    type: string;
    color?: string;
    onClick: Function;
  }>;
}

export enum GradingType {
  Dropdown,
  Number
}

export class GradingDialogData extends DialogData {
  gradingType: GradingType;
  allowedGradings?: DropdownData[];
}

export class SendSMSDialogData extends DialogData {
  recipient: string;
  client: any;
}

export interface DropdownData {
  name: string;
  value: any;
}

export class StudyResultDialogData extends DialogData {
  allowedGradings: DropdownData[];
  client: any; // TODO: Can't use Client here, should fix that.
  data: { // TODO: Create a class for this.
    id: number;
    type: string,
    resultType: string,
    clientStudyTrack: any,
    date: Date,
    result: number,
    remarks: string
  };
}

export interface IDashboardIcon {
  routerLink?: string;
  onClick?: () => void;
  color: string;
  matIcon: string;
  title: string;
  visibleAsAdmin?: boolean;
}

// -- Helper -- //
function autoImplement<T>(): new () => T {
  return class {
  } as any;
}

export enum StudentModuleKeys {
  Exams = 'exams',
  Courses = 'courses',
  Internship = 'internship',
  Generic = 'generic',
  ChoiceCourses = 'choiceCourses',
  ChoiceCoursesExams = 'choiceCourseExams',
  Tests = 'tests',
  PracticalExams = 'practicalExams',
  TheoreticalExams = 'theoreticalExams',
  Skills = 'skills',
  VirtualSkills = 'virtualSkills'
}
export class ActivityParticipant {
    id: number;
    remarks: string;
    @Type(() => ActivityResult)
    results: ActivityResult[];
    @Type(() => NestedClientStudyTrack)
    clientStudyTrack: NestedClientStudyTrack;
    clientStudyTrackId: number;
    @Type(() => Client)
    client: Client;
    clientId: number;
    @Type(() => Activity)
    activity: Activity;
    activityId: number;
}
type TCreateActivityParticipant = Omit<ActivityParticipant, 'id'| 'clientStudyTrack'| 'activity' >;
export class CreateActivityParticipant extends autoImplement<TCreateActivityParticipant>() {
}
type TUpdateActivityParticipant = Partial<Omit<ActivityParticipant, 'clientStudyTrack'| 'activity'| 'clientStudyTrackId'| 'results' >>;
export class UpdateActivityParticipant extends autoImplement<TUpdateActivityParticipant>() {
    @Type(() => UpdateActivityResult)
    results: UpdateActivityResult[];
}
export class ActivityResult {
    id: number;
    resultType: StudyResultType;
    result: number;
    @Type(() => StudyProfileComponent)
    studyProfileComponent: StudyProfileComponent;
    studyProfileComponentId: number;
    remarks: string;
    @Type(() => StudyComponent)
    component: StudyComponent;
    studyComponentId: number;
    @Type(() => ActivityParticipant)
    activityParticipant: ActivityParticipant;
    activityParticipantId: number;
    alreadyPassed?: boolean;
}
type TCreateActivityResult = Omit<ActivityResult, 'id'| 'studyProfileComponent'| 'component'| 'activityParticipant' >;
export class CreateActivityResult extends autoImplement<TCreateActivityResult>() {
}
type TUpdateActivityResult = Partial<Omit<ActivityResult, 'id'| 'studyProfileComponent'| 'component'| 'activityParticipant' >>;
export class UpdateActivityResult extends autoImplement<TUpdateActivityResult>() {
}
export class Activity {
    id: number;
    @Type(() => User)
    createdBy: User;
    name: string;
    date: Date;
    type: ActivityType;
    @Type(() => ActivityParticipant)
    participants: ActivityParticipant[];
    amountOfParticipants: number;
    status: ActivityStatus;
}
type TCreateActivity = Omit<Activity, 'id'| 'participants' >;
export class CreateActivity extends autoImplement<TCreateActivity>() {
    @Type(() => Number)
    clientStudyTrackIds: number[];
}
type TUpdateActivity = Partial<Omit<Activity, 'id'| 'participants' >>;
export class UpdateActivity extends autoImplement<TUpdateActivity>() {
    @Type(() => UpdateActivityParticipant)
    participants: UpdateActivityParticipant[];
}
export class AssignmentSubmission {
    id: number;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    @Type(() => Assignment)
    assignment: Assignment;
    assignmentId: number;
    remarks: string;
    @Type(() => StudyProfileComponent)
    studyProfileComponent: StudyProfileComponent;
    studyProfileComponentId: number;
    graded: boolean;
    @Type(() => StudyResult)
    studyResult: StudyResult;
    studyResultId: number;
    @Type(() => InternshipCompanyContact)
    contactPerson: InternshipCompanyContact;
    contactPersonId: number;
    @Type(() => InternshipCompany)
    internshipCompany: InternshipCompany;
    internshipCompanyId: number;
    assignmentDate: Date;
}
type TCreateAssignmentSubmission = Omit<AssignmentSubmission, 'id'| 'studyProfileComponent'| 'assignment'| 'studyResult' >;
export class CreateAssignmentSubmission extends autoImplement<TCreateAssignmentSubmission>() {
}
type TUpdateAssignmentSubmission = Partial<Omit<AssignmentSubmission, 'id'| 'studyProfileComponent'| 'assignment'| 'studyResult' >>;
export class UpdateAssignmentSubmission extends autoImplement<TUpdateAssignmentSubmission>() {
}
export class Assignment {
    id: number;
    name: string;
    description: string;
    identifier: string;
    type: AssignmentType;
    @Type(() => StudySpecificationComponentMeta)
    studySpecificationComponentMeta: StudySpecificationComponentMeta;
    studySpecificationComponentMetaId: number;
}
export class CreateAssignmentForStudyComponent {
    studySpecificationComponentMetaId: number;
}
type TCreateAssignment = Omit<Assignment, 'id'| 'studySpecificationComponentMeta' >;
export class CreateAssignment extends autoImplement<TCreateAssignment>() {
}
type TUpdateAssignment = Partial<Omit<Assignment, 'id'| 'studySpecificationComponentMeta' >>;
export class UpdateAssignment extends autoImplement<TUpdateAssignment>() {
}
export class BillingDetails {
    id: number;
    companyName: string;
    @Type(() => Address)
    @Type(() => Address)
    address: Address;
    @Type(() => Name)
    @Type(() => Name)
    name: Name;
    invoiceEmail: string;
    phoneNumber: string;
    description: string;
    type: BillingDetailsType;
}
type TCreateBillingDetails = Omit<BillingDetails, 'id' >;
export class CreateBillingDetails extends autoImplement<TCreateBillingDetails>() {
}
type TUpdateBillingDetails = Partial<Omit<BillingDetails, 'id' >>;
export class UpdateBillingDetails extends autoImplement<TUpdateBillingDetails>() {
}
export class CalendarEntry {
    id: number;
    @Type(() => Date)
    start: Date;
    @Type(() => Date)
    end: Date;
    title: string;
    description?: string;
    type?: CalendarEntryType;
}
type TCreateCalendarEntry = Omit<CalendarEntry, 'id' >;
export class CreateCalendarEntry extends autoImplement<TCreateCalendarEntry>() {
}
type TUpdateCalendarEntry = Partial<Omit<CalendarEntry, 'id' >>;
export class UpdateCalendarEntry extends autoImplement<TUpdateCalendarEntry>() {
}
export class ClientInternshipRequest {
    id: number;
    @Type(() => NestedClientStudyTrack)
    clientStudyTrack: NestedClientStudyTrack;
    clientStudyTrackId: number;
    @Type(() => InternshipCompany)
    company: InternshipCompany;
    companyId: number;
    companySbbNumber: string;
    status: ClientInternshipRequestStatus;
    companyName: string;
    postalCode: string;
    houseNr: string;
    houseNrSuffix: string;
    city: string;
    streetName: string;
    country: string;
    municipality: string;
    phoneNumber: string;
    email: string;
    @Type(() => InternshipCompanyContact)
    contactPerson: InternshipCompanyContact;
    contactPersonId: number;
    contactPersonInitials: string;
    contactPersonFirstName: string;
    contactPersonMiddleName: string;
    contactPersonLastName: string;
    contactPersonPhoneNumber: string;
    contactPersonEmail: string;
    contactPersonTypes: GlobalType[];
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    pokRemoteDocumentId: number;
    @Type(() => Date)
    bpvStartDate: Date;
    @Type(() => Date)
    bpvEndDate: Date;
    @Type(() => StudyModule)
    choicePackageOne: StudyModule;
    choicePackageOneId: number;
    choicePackageOneBpvHours: number;
    @Type(() => StudyModule)
    choicePackageTwo: StudyModule;
    choicePackageTwoId: number;
    choicePackageTwoBpvHours: number;
    @Type(() => StudyModule)
    choicePackageThree: StudyModule;
    choicePackageThreeId: number;
    choicePackageThreeBpvHours: number;
    bpvDivision: number;
    bpvHours: number;
    @Type(() => GlobalType)
    type: GlobalType;
    typeId: number;
}
type TCreateClientInternshipRequest = Omit<ClientInternshipRequest, 'id'| 'company'| 'clientStudyTrack' >;
export class CreateClientInternshipRequest extends autoImplement<TCreateClientInternshipRequest>() {
}
type TUpdateClientInternshipRequest = Partial<Omit<ClientInternshipRequest, 'id'| 'company'| 'clientStudyTrack' >>;
export class UpdateClientInternshipRequest extends autoImplement<TUpdateClientInternshipRequest>() {
}
export class ClientInternship {
    id: number;
    startDate: Date;
    endDate: Date;
    @Type(() => ClientStudyTrack)
    clientStudyTrack: ClientStudyTrack;
    @Type(() => InternshipCompany)
    company: InternshipCompany;
    companyId: number;
    @Type(() => InternshipCompanyContact)
    contactPerson: InternshipCompanyContact;
    contactPersonId: number;
    status: ClientInternshipStatus;
    isCurrentlyIntern: boolean;
    @Type(() => InternshipCompanyContact)
    internshipCompanyAssessor: InternshipCompanyContact;
    @Type(() => GlobalType)
    type: GlobalType;
    typeId: number;
}
type TCreateClientInternship = Omit<ClientInternship, 'id'| 'clientStudyTrack'| 'company'| 'contactPerson'| 'internshipCompanyAssessor'| 'type' >;
export class CreateClientInternship extends autoImplement<TCreateClientInternship>() {
    clientStudyTrackId: number;
    companyId: number;
    contactPersonId: number;
    internshipCompanyAssessorId?: number;
}
type TNestedClientInternship = Omit<ClientInternship, 'clientStudyTrack' >;
export class NestedClientInternship extends autoImplement<TNestedClientInternship>() {
    clientStudyTrackId: number;
}
type TUpdateClientInternship = Partial<Omit<ClientInternship, 'id'| 'clientStudyTrack'| 'internshipCompanyAssessor' >>;
export class UpdateClientInternship extends autoImplement<TUpdateClientInternship>() {
}
export class ClientStudyTrackCertification {
    id: number;
    @Type(() => User)
    officiator: User;
    @Type(() => Date)
    dateStart: Date;
    @Type(() => Date)
    dateDecided: Date;
    @Type(() => Date)
    issuanceDate: Date;
    @Type(() => Date)
    handedOverDate: Date;
    remarks: string;
    decision: CertificationDecision;
    @Type(() => StudyResult)
    studyResult: StudyResult;
}
type TCreateClientStudyTrackCertification = Omit<ClientStudyTrackCertification, 'id' >;
export class CreateClientStudyTrackCertification extends autoImplement<TCreateClientStudyTrackCertification>() {
}
type TUpdateClientStudyTrackCertification = Partial<Omit<ClientStudyTrackCertification, 'id' >>;
export class UpdateClientStudyTrackCertification extends autoImplement<TUpdateClientStudyTrackCertification>() {
}
export class AssessmentGrading {
    remarks: string;
    result: number;
}
export class CertificationDecisionResult {
    remarks: string;
    result: number;
    officiatorId: number;
    @Type(() => Date)
    issuanceDate: Date;
}
export class ClientStudyTrack {
    id: number;
    number: string;
    @Type(() => Date)
    start: Date;
    @Type(() => Date)
    end: Date;
    @Type(() => StudySpecification)
    studySpecification: StudySpecification;
    @Type(() => Client)
    client: Client;
    status: StudyPhase;
    type: StudyType;
    @Type(() => StudyClass)
    studyClass: StudyClass;
    clientId: number;
    @Type(() => NestedClientInternship)
    internship: NestedClientInternship[];
    @Type(() => StudyResult)
    results: StudyResult[];
    choiceCoursePackage: ChoiceCoursePackage;
    goStatus: GoStatus;
    @Type(() => User)
    assessor: User;
    @Type(() => Date)
    assessorAssignedDate: Date;
    @Type(() => CalendarEntry)
    assessmentCalendarEntry: CalendarEntry;
    certificationPhase: CertificationPhase;
    @Type(() => ClientStudyTrackCertification)
    clientStudyTrackCertification: ClientStudyTrackCertification;
    label: string;
    registeredWithDUO: boolean;
    documentsSent: boolean;
    documentsSigned: boolean;
    loginCodesSent: boolean;
    hasPaid: boolean;
    @Type(() => StudyProfile)
    studyProfile: StudyProfile;
    studyProfileId: number;
    botHours: number;
    bpvHours: number;
    @Type(() => OldClientInternship)
    oldClientInternship: OldClientInternship;
    @Type(() => StudyStopReason)
    studyStopReason: StudyStopReason;
    studyStopReasonId: number;
    hasAccessToCareup: boolean;
}
export class CloseClientStudyTrack {
    remarks: string;
    @Type(() => Date)
    date: Date;
    stopCategoryId: number; // strictly we don't need the category if we have a StopReason below, as it has a reference to it's parent category.
    stopReasonId: number;
}
type TCreateClientStudyTrack = Omit<ClientStudyTrack, 'id'| 'studySpecification'| 'studyClass'| 'results'| 'end'| 'client' >;
export class CreateClientStudyTrack extends autoImplement<TCreateClientStudyTrack>() {
    studySpecificationId: number;
    studyId: number;
    studyClassId: number;
    internshipId: number;
}
export class GoGrading {
    remarks: string;
    result: number;
}
type TNestedClientStudyTrack = Omit<ClientStudyTrack, 'client'| 'results' >;
export class NestedClientStudyTrack extends autoImplement<TNestedClientStudyTrack>() {
}
type TUpdateClientStudyTrack = Partial<Omit<ClientStudyTrack, 'id'| 'studySpecification'| 'studyClass'| 'results'| 'end'| 'client'| 'clientId'| 'type' >>;
export class UpdateClientStudyTrack extends autoImplement<TUpdateClientStudyTrack>() {
    studySpecificationId?: number;
    studyId?: number;
    studyClassId?: number;
    internshipId?: number;
    studyStopReasonId?: number;
}
export class ClientQuizSession {
    sessionId: number;
    quizId: number;
    quizName: string;
    moduleId: number;
    moduleName: string;
    start: Date;
    end: Date;
    totalPointsReceived: number;
    totalPoints: number;
    minimumPointsToPass: number;
}
export class Client {
    id: number;
    number: string;
    userId: number;
    @Type(() => PersonalDetails)
    personalDetails: PersonalDetails;
    @Type(() => MemoEntry)
    memos: MemoEntry[];
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    @Type(() => NestedClientStudyTrack)
    studyTracks: NestedClientStudyTrack[];
    status: ClientStatus;
    @Type(() => ExternalCommunication)
    communications: ExternalCommunication[];
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
    uploadStorageId: string;
    active: boolean;
    superSaasAccountEmail: string;
    internalAccountEmail: string;
    @Type(() => SurveyResponse)
    responses: SurveyResponse[];
    quizSessions: ClientQuizSession[];
}
type TCreateClient = Omit<Client, 'id'| 'number'| 'personalDetails'| 'memos'| 'studyTracks'| 'attachments'>;
export class CreateClient extends autoImplement<TCreateClient>() {
    @Type(() => PersonalDetails)
    personalDetails: PersonalDetails;
}
type TUpdateClient = Partial<Omit<Client, 'id'| 'memos'| 'studyTracks'>>;
export class UpdateClient extends autoImplement<TUpdateClient>() {
}
type TCreateCronJob = Omit<CronJob, 'id' >;
export class CreateCronJob extends autoImplement<TCreateCronJob>() {
}
export class CronJob {
    id: number;
    type: CronJobType;
    data: any;
    @Type(() => Date)
    @Type(() => Date)
    scheduledFor: Date;
}
type TUpdateCronJob = Partial<Omit<CronJob, 'id' >>;
export class UpdateCronJob extends autoImplement<TUpdateCronJob>() {
}
type TCreateExternalCommunication = Omit<ExternalCommunication, 'id' >;
export class CreateExternalCommunication extends autoImplement<TCreateExternalCommunication>() {
}
export class ExternalCommunication {
    id: number;
    createdAt: Date;
    externalRef: string;
    parentRef?: string;
    title: string;
    description?: string;
    content?: string;
    @Type(() => User)
    sender?: User;
    @Type(() => User)
    recipient?: User;
    senderMail?: string;
    recipientMail?: string;
    @Type(() => Client)
    recipientClient?: Client;
    protocol: ExternalCommunicationProtocol;
    type: ExternalCommunicationType;
    direction: ExternalCommunicationDirection;
    status: ExternalCommunicationStatus;
    labels: string[];
}
export class SendBulkSMSMessage {
    title: string;
    content: string;
    recipientUserIds?: number[];
    recipientClientIds?: number[];
    recipientPhoneNumbers: string[];
}
export class SendSMSMessage {
    title: string;
    content: string;
    recipientUserId?: number;
    recipientClientId?: number;
    recipientPhoneNumber: string;
}
type TUpdateExternalCommunication = Partial<Omit<ExternalCommunication, 'id' >>;
export class UpdateExternalCommunication extends autoImplement<TUpdateExternalCommunication>() {
}
type TCreateGlobalType = Omit<GlobalType, 'id' >;
export class CreateGlobalType extends autoImplement<TCreateGlobalType>() {
}
export class GlobalType {
    id: number;
    taxonomy: GlobalTypeTaxonomy;
    name: string;
    display: string;
    @Type(() => GlobalType)
    parent: GlobalType;
    parentId: number;
}
type TUpdateGlobalType = Partial<Omit<GlobalType, 'id' >>;
export class UpdateGlobalType extends autoImplement<TUpdateGlobalType>() {
}
export class CareupLogin {
}
export class CareupProtocol {
}
export class CareupScore {
}
export class CareupUser {
}
export class Address {
    postalCode: string;
    houseNr: string;
    houseNrSuffix: string;
    city: string;
    streetName: string;
    country: string;
    municipality: string;
}
type TCreateMemoEntry = Omit<MemoEntry, 'id'| 'createdAt'>;
export class CreateMemoEntry extends autoImplement<TCreateMemoEntry>() {
}
export class ErrorResponse {
    code: string;
    message: string;
    errors: Error[];
}
export class Error {
    field: string;
    error: string;
}
export class MemoEntry {
    id: number;
    createdAt: Date;
    @Type(() => User)
    createdBy: User;
    content: string;
    type: MemoType;
}
export class Name {
    initials: string;
    firstName: string;
    middleName: string;
    lastName: string;
}
export class OldClientInternship {
    clientStudyTrackId: number;
    contactPersonName: Name;
    companyName: string;
    address: Address;
    phoneNumber: string;
    email: string;
    status: string;
    isCurrentlyIntern: boolean;
}
export class PersonalDetails {
    @Type(() => Name)
    name: Name;
    passportName: string;
    dateOfBirth: Date;
    gender: Gender;
    pronoun: string;
    phoneNumber: string;
    email: string;
    @Type(() => Address)
    address: Address;
}
type TCreateInternshipCompanyContact = Omit<InternshipCompanyContact, 'id' >;
export class CreateInternshipCompanyContact extends autoImplement<TCreateInternshipCompanyContact>() {
    @Type(() => GlobalType)
    types: GlobalType[];
}
export class InternshipCompanyContact {
    id: number;
    @Type(() => Name)
    contactPersonName: Name;
    phoneNumber: string;
    email: string;
    @Type(() => InternshipCompany)
    companies: InternshipCompany[];
    active: boolean;
    @Type(() => GlobalType)
    types: GlobalType[];
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
    @Type(() => MemoEntry)
    memos: MemoEntry[];
    @Type(() => ExternalCommunication)
    communications: ExternalCommunication[];
    @Type(() => SurveyResponse)
    surveys: SurveyResponse[];
    qualifiedUntil: Date;
    fullName: string;
    @Type(() => Address)
    address: Address;
    @Type(() => ClientInternship)
    internships: ClientInternship[];
}
type TUpdateInternshipCompanyContact = Partial<Omit<InternshipCompanyContact, 'id' >>;
export class UpdateInternshipCompanyContact extends autoImplement<TUpdateInternshipCompanyContact>() {
}
type TCreateInternshipCompany = Omit<InternshipCompany, 'id'| 'skills'>;
export class CreateInternshipCompany extends autoImplement<TCreateInternshipCompany>() {
    @Type(() => GlobalType)
    types: GlobalType[];
}
export class InternshipCompany {
    id: number;
    number: string;
    active: boolean;
    companyName: string;
    sbbNumber: string;
    @Type(() => Address)
    address: Address;
    phoneNumber: string;
    email: string;
    labels: CompanyLabel[];
    region: CompanyRegion;
    @Type(() => ClientInternship)
    clientInternships: ClientInternship[];
    @Type(() => InternshipCompanyContact)
    contactPersons: InternshipCompanyContact[];
    @Type(() => InternshipSkill)
    skills: InternshipSkill[];
    @Type(() => MemoEntry)
    memos: MemoEntry[];
    isBlacklisted: boolean;
    lessonsOnLocation: boolean;
    amountOfActiveInternships: number;
    @Type(() => ExternalCommunication)
    communications: ExternalCommunication[];
    @Type(() => SurveyResponse)
    @Type(() => SurveyResponse)
    responses: SurveyResponse[];
    @Type(() => GlobalType)
    types: GlobalType[];
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
}
type TUpdateInternshipCompany = Partial<Omit<InternshipCompany, 'id'>>;
export class UpdateInternshipCompany extends autoImplement<TUpdateInternshipCompany>() {
}
type TCreateInternshipSkill = Omit<InternshipSkill, 'id' >;
export class CreateInternshipSkill extends autoImplement<TCreateInternshipSkill>() {
}
export class InternshipSkill {
    id: number;
    title: string;
    companies: InternshipCompany[];
}
type TUpdateInternshipSkill = Partial<Omit<InternshipSkill, 'id' >>;
export class UpdateInternshipSkill extends autoImplement<TUpdateInternshipSkill>() {
}
export class CohortInfo {
    studentsQuit: number;
    studentsQuitPerReason: { [stopCategoryId: number]: { total: number, data: {[stopReasonId: number]: number}} };
    studentsFinished: number;
    averageDurationPerStudentMonths: number;
    studentsStarted: { total: number, totalDevelopment: number, totalExams: number, labels: string[], data: { [studyId: number]: { total: number, inActivePhase: number, graduated: number } } };
}
export class Counts {
    total: number;
    stillOngoing: number;
    sinceCertified: number;
    sinceQuit: number;
}
export class ElearningattempsDto {
  clientQuiz_id: number;
  clientQuiz_title: string;
  attempts: number;
  countQuizOpened: number;
  maxAttempts: number;
}

export class ElearningInfo {
    sessionId: number;
    clientNumber: string;
    clientCohort: string;
    clientName: string;
    study: string;
    quizName: string;
    moduleName: string;
    start: string;
    end: string;
    totalPointsReceived: number;
    totalPoints: number;
    minimumPointsToPass: number;
    maxQuizAttempt: number;
}
export class ExamInfo {
    totalStudentsTakingExams: number;
    certificationsIssuedTotal: number;
    certificationsIssued: { [studyId: number]: number };
    successRate: { [studyId: number]: { successRate: number, graduated: number, total: number } };
    averageDurationPerStudent: { [studyId: number]: number };
    successRateWithinPlannedTime: { [studyId: number]: { plannedTimeMonths: number, successRate: number, total: number, withinPlannedTime: number } };
}
export class Graph {
}
export type InternStudyResultDto = {
}
export class KPITotals {
    totalStudents: number;
    totalStudentsDevelopmentPhase: number;
    totalStudentsQualifyingPhase: number;
    totalInternshipCompanies: number;
    totalActiveInternshipCompanies: number;
    totalBpvCompanies: number;
    totalExamCompanies: number;
    totalActiveBpvCompanies: number;
    totalActiveExamCompanies: number;
    totalActiveInternships: number;
                development: number;
}
export class PieChart {
}
export class PollInfo {
    totalAnswers: number;
        answerType: 'choice';
}
export class PracticeInfo {
    totalCompanies: number;
    totalBpvLocations: number;
    totalExamLocations: number;
    totalStudentsStayingAtBpvForExams: number;
}
export class UseInitialPassword {
    email: string;
    token: string;
    password: string;
}
type TCreateQuizCasusQuestion = Omit<QuizCasusQuestion, 'id'| 'casus'| 'answers' >;
export class CreateQuizCasusQuestion extends autoImplement<TCreateQuizCasusQuestion>() {
}
export class QuizCasusQuestion {
    id: number;
    description: string;
    type: QuizQuestionType;
    answerModel: any = [{value: '', correct: null}]; //FIXME
    points1: number;
    points2: number;
    points3plus: number;
    explanationRequired: boolean;
    feedbackCorrect: string;
    feedbackIncorrect: string;
    casus: QuizCasus;
    caseId: number;
    answers: QuizSessionAnswer[];
    order: number;
    maxAttempts: number;
    showFeedback: boolean;
}
type TUpdateQuizCasusQuestion = Partial<Omit<QuizCasusQuestion, 'id'| 'casus'| 'answers' >>;
export class UpdateQuizCasusQuestion extends autoImplement<TUpdateQuizCasusQuestion>() {
}
type TCreateQuizCasus = Omit<QuizCasus, 'id'| 'attachments'| 'questions' >;
export class CreateQuizCasus extends autoImplement<TCreateQuizCasus>() {
}
export class QuizCasus {
    id: number;
    description: string;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
    videoUrl: string;
    quiz: Quiz;
    quizId: number;
    questions: QuizCasusQuestion[] = [];
    order: number;
}
type TUpdateQuizCasus = Partial<Omit<QuizCasus, 'id'| 'questions'| 'attachments' >>;
export class UpdateQuizCasus extends autoImplement<TUpdateQuizCasus>() {
}
type TCreateQuizSessionAnswer = Omit<QuizSessionAnswer, 'id'| 'question'| 'session' >;
export class CreateQuizSessionAnswer extends autoImplement<TCreateQuizSessionAnswer>() {
}
export class QuizSessionAnswer {
    id: number;
    session: QuizSession;
    sessionId: number;
    question: QuizCasusQuestion;
    questionId: number;
    answer: string;
    explanation: string;
    tries: number;
    correct: boolean;
    points: number;
}
type TUpdateQuizSessionAnswer = Partial<Omit<QuizSessionAnswer, 'id'| 'question'| 'session' >>;
export class UpdateQuizSessionAnswer extends autoImplement<TUpdateQuizSessionAnswer>() {
}
type TCreateQuizSession = Omit<QuizSession, 'id'| 'quiz'| 'answers' >;
export class CreateQuizSession extends autoImplement<TCreateQuizSession>() {
}
export class QuizSession {
    id: number;
    user: User;
    userId: number;
    @Type(() => ClientStudyTrack)
    clientStudyTrack: ClientStudyTrack[];
    clientStudyTrackId: number;
    quiz: Quiz;
    quizId: number;
    finalized: boolean;
    start: Date;
    end: Date;
    answers: QuizSessionAnswer[];
    totalPointsReceived: number;
    currentCaseIndex: number;
    currentQuestionIndex: number;
}
type TUpdateQuizSession = Partial<Omit<QuizSession, 'id'| 'quiz'| 'answers'| 'start' >>;
export class UpdateQuizSession extends autoImplement<TUpdateQuizSession>() {
}
type TCreateQuiz = Omit<Quiz, 'id'| 'studyModuleSpecification'| 'cases' >;
export class CreateQuiz extends autoImplement<TCreateQuiz>() {
}
export class Quiz {
    id: number;
    title: string;
    description: string;
    minimumPointsToPass: number;
    totalPoints: number;
    studyModuleSpecificationId: number;
    studyModuleSpecification: StudyModuleSpecification;
    cases: QuizCasus[] = [];
    amountOfCases: number;
    amountOfQuestions: number;
    maxAttempts: number;
}
type TUpdateQuiz = Partial<Omit<Quiz, 'id'| 'studyModuleSpecification'| 'cases' >>;
export class UpdateQuiz extends autoImplement<TUpdateQuiz>() {
}
type TCreateRemoteDocument = Omit<RemoteDocument, 'id' >;
export class CreateRemoteDocument extends autoImplement<TCreateRemoteDocument>() {
}
export class DocUpload {
    type: string;
    name: string;
    file?: any;
}
export class FullRemoteDocument {
    data: string;
}
export class ImportResults {
}
export class RemoteDocument {
    id: number;
    createdAt: Date;
    name: string;
    uri: string;
    mimeType: string;
    protocol: string;
    type: RemoteDocType;
}
type TUpdateRemoteDocument = Partial<Omit<RemoteDocument, 'id' >>;
export class UpdateRemoteDocument extends autoImplement<TUpdateRemoteDocument>() {
}
export class SearchResult {
    type: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    email: string;
    phone: string;
    dob: Date;
    number: string;
    id: string;
    active: string;
    status: string;
}
export class SearchResults {
    results: SearchResult[];
    amount: number;
}
export class AnswerQuizQuestion {
    answer: string;
    explanation?: string;
}
export class CreateStudentAssignmentSubmission {
    assignmentId: number;
    studyProfileComponentId: number;
    remarks: string;
    @Type(() => InternshipCompanyContact)
    contactPerson: InternshipCompanyContact;
    contactPersonId: number;
    @Type(() => InternshipCompany)
    internshipCompany: InternshipCompany;
    internshipCompanyId: number;
    assignmentDate: Date;
}
export class MissingContactPerson {
    studyProfileComponentId: number;
    clientStudyTrackId: number;
    remarks: string;
}
export class StudentAssignmentSubmission {
    id: number;
    assignmentId: number;
    remarks: string;
    graded: boolean;
    result: StudentStudyResult;
}
export class StudentAssignment {
    id: number;
    name: string;
    identifier: string;
    type: string;
}
export class StudentClient {
    id: number;
    number: string;
    userId: number;
    @Type(() => PersonalDetails)
    personalDetails: PersonalDetails;
    @Type(() => StudentClientStudyTrack)
    studyTracks: StudentClientStudyTrack[];
    status: ClientStatus;
    active: boolean;
    superSaasAccountEmail: string;
    internalAccountEmail: string;
}
export class StudentModuleLesson {
    id: number;
    order: number;
    name: string;
    description: string;
}
export class StudentModuleProgress {
    studyModule: StudentStudyModule;
    components: StudentProfileComponent[];
    percentageComplete: number;
    studyModuleSpecificationId: number;
}
export class StudentModulesTimeline {
    id: number;
    ssm_id: number;
    order: number;
    name: string;
    code: string;
    lessons: StudentModuleLesson[];
}
export class StudentModules {
    exams: StudentModuleProgress[];
    courses: StudentModuleProgress[];
    internship: StudentModuleProgress[];
    generic: StudentModuleProgress[];
    choiceCourses: StudentModuleProgress[];
    tests: StudentModuleProgress[];
    choiceCourseExams: StudentModuleProgress[];
    practicalExams: StudentModuleProgress[];
    theoreticalExams: StudentModuleProgress[];
    skills: StudentModuleProgress[];
    virtualSkills: StudentModuleProgress[];
}
export class StudentProfileComponent {
    id: number;
    componentId: number;
    componentSpecId: number;
    results: StudentStudyResult[];
    assignmentSubmissions: StudentAssignmentSubmission[];
    assignments: StudentAssignment[];
    isPassed: boolean;
    isAvailable: boolean;
    name: string;
    code: string;
}
export class StudentProgress {
    hasCompletedDevelopmentPhase: boolean;
}
export class StudentQuiz {
    quiz: Quiz;
    sessions: QuizSession[];
}
export class StudentStudyComponentSpec {
    id: number;
    studyComponentId: number;
    @Type(() => StudyComponent)
    studyComponent: StudyComponent;
    @Type(() => Assignment)
    assignments: Assignment[];
}
export class StudentStudyModule {
    id: number;
    name: number;
    code: number;
    description: string;
    sbuHours: number;
}
export class StudentStudyResult {
    id: number;
    createdAt: Date;
    date: Date;
    result: number;
    type: string;
}
type TStudentClientStudyTrack = Omit<ClientStudyTrack, 'client'| 'results'| 'assessor'| 'assessorAssignedDate'| 'assessmentCalendarEntry'| 'certificationPhase'| 'clientStudyTrackCertification'| 'label'| 'registeredWithDUO'| 'documentsSent'| 'documentsSigned'| 'loginCodesSent'| 'hasPaid'| 'studyProfile' >;
export class StudentClientStudyTrack extends autoImplement<TStudentClientStudyTrack>() {
}
type TCreateStudyApplication = Omit<StudyApplication, 'id'| 'client'| 'intakeDateSuggestions'| 'number'| 'retrievedClientDetails'>;
export class CreateStudyApplication extends autoImplement<TCreateStudyApplication>() {
    clientId?: number;
    @Type(() => CreateClient)
    client?: CreateClient;
    intakeDateSuggestions?: number[];
}
export class IntakeGrading {
    intakeGradingNote?: string;
    intakeHealthcareLevel: IntakeHealthcareLevel;
    languageHelpNeeded: boolean;
    intakeGradingResult: IntakeGradingResult;
}
export class StudyApplicationGrading {
    intakeCalendarEntry: CalendarEntry;
    intakePlannerNote: string;
    status: StudyApplicationStatus;
    doNotSendMail: boolean;
}
export class StudyApplicationQuestion {
    question: string;
    answer: string;
}
export class StudyApplication {
    id: number;
    number: string;
    createdDate: Date;
    updatedDate: Date;
    applicationDate: Date;
    @Type(() => CalendarEntry)
    intakeCalendarEntry: CalendarEntry;
    @Type(() => StudySpecification)
    studySpecification: StudySpecification;
    @Type(() => Study)
    study: Study;
    status: StudyApplicationStatus;
    @Type(() => Client)
    client: Client;
    @Type(() => PersonalDetails)
    retrievedClientDetails: PersonalDetails;
    educationLevel: PreviousEducationLevel;
    applicationSource: StudyApplicationSource;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    foreignDiploma: ForeignDiploma;
    agreedToTerms: boolean;
    agreedToFinancialObligation: boolean;
    hasInternship: boolean;
    hasComputer: boolean;
    paymentBy: PaymentBy;
    @Type(() => BillingDetails)
    billingDetails: BillingDetails;
    questionnaire: StudyApplicationQuestion[];
    @Type(() => CalendarEntry)
    intakeDateSuggestions: CalendarEntry[];
    intakePlannerNote: string;
    intakeGradingResult: IntakeGradingResult;
    @Type(() => User)
    intakeGradingBy: User;
    intakeGradingNote: string;
    intakeHealthcareLevel: IntakeHealthcareLevel;
    languageHelpNeeded: boolean;
    clientFoundAutomatically: boolean;
    sendExemptionDoc: boolean;
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
    @Type(() => MemoEntry)
    memos: MemoEntry[];
}
type TUpdateStudyApplication = Partial<Omit<StudyApplication, 'id'| 'number'| 'client'| 'agreedToTerms'| 'agreedToFinancialObligation'| 'applicationSource'| 'applicationDate'| 'createdDate'| 'updatedDate'>>;
export class UpdateStudyApplication extends autoImplement<TUpdateStudyApplication>() {
    clientId?: number;
}
type TCreateStudyClass = Omit<StudyClass, 'id'| 'study'| 'studySpecification'| 'contactPerson'| 'clientStudyTracks'| 'amountOfClientTracks' >;
export class CreateStudyClass extends autoImplement<TCreateStudyClass>() {
    contactPersonId: number;
}
export class StudyClass {
    id: number;
    @Type(() => Study)
    study: Study;
    studyId: number;
    @Type(() => StudySpecification)
    studySpecification: StudySpecification;
    studySpecificationId: number;
    code: string;
    @Type(() => NestedClientStudyTrack)
    clientStudyTracks: NestedClientStudyTrack[];
    amountOfClientTracks: number;
    @Type(() => User)
    contactPerson: User;
    @Type(() => Date)
    start: Date;
    @Type(() => MemoEntry)
    memos: MemoEntry[];
}
type TUpdateStudyClass = Partial<Omit<StudyClass, 'id'| 'clientStudyTracks'| 'amountOfClientTracks'| 'contactPerson' >>;
export class UpdateStudyClass extends autoImplement<TUpdateStudyClass>() {
    contactPersonId: number;
}
type TCreateStudyComponent = Omit<StudyComponent, 'id' >;
export class CreateStudyComponent extends autoImplement<TCreateStudyComponent>() {
}
export class StudyComponent {
    id: number;
    name: string;
    code: string;
    description: string;
    type: StudyComponentType;
    remarks?: string;
    botHours: number;
    externalRef?: string;
}
type TUpdateStudyComponent = Partial<Omit<StudyComponent, 'id' >>;
export class UpdateStudyComponent extends autoImplement<TUpdateStudyComponent>() {
}
type TCreateStudyModuleLesson = Omit<StudyModuleLesson, 'id'| 'attachments'| 'studyModuleSpecification' >;
export class CreateStudyModuleLesson extends autoImplement<TCreateStudyModuleLesson>() {
}
export class LessonDocUpload {
    type: string;
    name: string;
    file?: any;
    materialType: LessonDocType;
}
export class StudyModuleLesson {
    id: number;
    studyModuleSpecificationId: number;
    @Type(() => StudyModule)
    studyModuleSpecification: StudyModuleSpecification;
    order: number;
    name: string;
    description: string;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    presentationRemoteDocumentId: number;
    readerRemoteDocumentId: number;
    attachmentsFreelyDownloadable: boolean;
}
type TUpdateStudyModuleLesson = Partial<Omit<StudyModuleLesson, 'id'| 'studyModuleSpecification' >>;
export class UpdateStudyModuleLesson extends autoImplement<TUpdateStudyModuleLesson>() {
}
type TCreateStudyModulePackage = Omit<StudyModulePackage, 'id' >;
export class CreateStudyModulePackage extends autoImplement<TCreateStudyModulePackage>() {
}
export class StudyModulePackage {
    id: number;
}
type TUpdateStudyModulePackage = Partial<Omit<StudyModulePackage, 'id' >>;
export class UpdateStudyModulePackage extends autoImplement<TUpdateStudyModulePackage>() {
}
type TCreateStudyModuleSpecification = Omit<StudyModuleSpecification, 'id' >;
export class CreateStudyModuleSpecification extends autoImplement<TCreateStudyModuleSpecification>() {
    existingId?: number;
}
export class StudyModuleSpecification {
    id: number;
    @Type(() => Date)
    start: Date;
    @Type(() => Date)
    end?: Date;
    @Type(() => StudySpecificationComponentMeta)
    components: StudySpecificationComponentMeta[];
    @Type(() => StudyModule)
    studyModule: StudyModule;
    studyModuleId: number;
    cohort: string;
    code: string;
    type: string;
    description?: string;
    sbuHours: number;
    @Type(() => StudySpecificationModule)
    studySpecificationModules: StudySpecificationModule[];
    @Type(() => StudyModuleLesson)
    lessons: StudyModuleLesson[];
    introMediaLink?: string;
    introText?: string;
    attachments: RemoteDocument[];
    externalStorageId: string;
    externalStorageType: RemoteDocProtocol;
    quizzes: Quiz[];
}
type TUpdateStudyModuleSpecification = Partial<Omit<StudyModuleSpecification, 'id' >>;
export class UpdateStudyModuleSpecification extends autoImplement<TUpdateStudyModuleSpecification>() {
}
type TCreateStudyModule = Omit<StudyModule, 'id' >;
export class CreateStudyModule extends autoImplement<TCreateStudyModule>() {
}
export class StudyModule {
    id: number;
    name: string;
    code: string;
    type: StudyModuleType;
    description?: string;
    @Type(() => StudyModuleSpecification)
    studyModuleSpecifications: StudyModuleSpecification[];
}
type TUpdateStudyModule = Partial<Omit<StudyModule, 'id' >>;
export class UpdateStudyModule extends autoImplement<TUpdateStudyModule>() {
}
type TCreateStudyProfileComponent = Omit<StudyProfileComponent, 'id' >;
export class CreateStudyProfileComponent extends autoImplement<TCreateStudyProfileComponent>() {
}
export class StudyProfileComponent {
    id: number;
    @Type(() => StudyProfile)
    studyProfile: StudyProfile;
    studyProfileId: StudyProfile;
    @Type(() => StudySpecificationComponentMeta)
    studySpecificationComponentMeta: StudySpecificationComponentMeta;
    studySpecificationComponentMetaId: StudyProfile;
    @Type(() => StudyResult)
    studyResult: StudyResult[];
    studyResultId: StudyProfile;
    isPassed: boolean;
    @Type(() => AssignmentSubmission)
    assignmentSubmissions: AssignmentSubmission[];
    addedManually: boolean;
}
type TUpdateStudyProfileComponent = Partial<Omit<StudyProfileComponent, 'id' >>;
export class UpdateStudyProfileComponent extends autoImplement<TUpdateStudyProfileComponent>() {
}
export class AddProfileModule {
    studyModuleId: number;
    studyModuleSpecificationId: number;
}
type TCreateStudyProfile = Omit<StudyProfile, 'id' >;
export class CreateStudyProfile extends autoImplement<TCreateStudyProfile>() {
}
export class StudyProfile {
    id: number;
    @Type(() => NestedClientStudyTrack)
    clientStudyTrack: NestedClientStudyTrack;
    clientStudyTrackId: number;
    @Type(() => StudyProfileComponent)
    studyProfileComponents: StudyProfileComponent[];
}
type TUpdateStudyProfile = Partial<Omit<StudyProfile, 'id' >>;
export class UpdateStudyProfile extends autoImplement<TUpdateStudyProfile>() {
}
type TCreateStudyResult = Omit<StudyResult, 'id' >;
export class CreateStudyResult extends autoImplement<TCreateStudyResult>() {
    studyComponentId?: number;
    createdById?: number;
    locationType?: string;
    internshipCompanyId?: number;
}
export class MultipleStudyResults {
    @Type(() => CreateStudyResult)
    @Type(() => CreateStudyResult)
    data: CreateStudyResult[];
    doNotSendMail: boolean;
}
export class StudyResult {
    id: number;
    date: Date;
    createdAt: Date;
    type: StudyComponentType;
    resultType: StudyResultType;
    isPassed: boolean;
    result: number;
    countsForAverage: boolean;
    clientId: number;
    clientStudyTrackId: number;
    @Type(() => NestedClientStudyTrack)
    clientStudyTrack: NestedClientStudyTrack;
    @Type(() => StudyProfileComponent)
    studyProfileComponent: StudyProfileComponent;
    studyProfileComponentId: number;
    remarks: string;
    @Type(() => StudyComponent)
    component: StudyComponent;
    doNotSendMail: boolean;
    @Type(() => InternshipCompanyContact)
    contactPerson: InternshipCompanyContact;
    contactPersonId?: number;
}
type TUpdateStudyResult = Partial<Omit<StudyResult, 'id' >>;
export class UpdateStudyResult extends autoImplement<TUpdateStudyResult>() {
    clientStudyTrackId?: number;
}
type TAddComponentToStudyModuleSpecification = Omit<StudySpecificationComponentMeta, 'id'| 'studyModuleSpecificationId'| 'studyComponent' >;
export class AddComponentToStudyModuleSpecification extends autoImplement<TAddComponentToStudyModuleSpecification>() {
}
type TCreateStudySpecificationComponentMeta = Omit<StudySpecificationComponentMeta, 'id' >;
export class CreateStudySpecificationComponentMeta extends autoImplement<TCreateStudySpecificationComponentMeta>() {
}
export class PassCondition {
    type: PassingConditionType;
    value: number;
}
export class StudySpecificationComponentMeta {
    id: number;
    studySpecificationId: number;
    @Type(() => StudyModuleSpecification)
    studyModuleSpecification: StudyModuleSpecification[];
    studyModuleSpecificationId: number;
    studyComponentId: number;
    @Type(() => StudyComponent)
    studyComponent: StudyComponent[];
    metaType: StudySpecificationComponentMetaType;
    passConditions: PassCondition[];
    resultType: StudyResultType;
    weight: number;
    allowedFreeRetries: number;
    botHours: number;
    assignments: Assignment[];
    submittable: boolean;
}
type TUpdateStudySpecificationComponentMeta = Partial<Omit<StudySpecificationComponentMeta, 'id' >>;
export class UpdateStudySpecificationComponentMeta extends autoImplement<TUpdateStudySpecificationComponentMeta>() {
}
type TCreateStudySpecificationModule = Omit<StudySpecificationModule, 'id'| 'studyModuleSpecification'| 'studySpecification' >;
export class CreateStudySpecificationModule extends autoImplement<TCreateStudySpecificationModule>() {
    studyModuleId: number;
}
export class StudySpecificationModule {
    id: number;
    order: number;
    studyModuleSpecificationId: number;
    @Type(() => StudyModuleSpecification)
    studyModuleSpecification: StudyModuleSpecification;
    studySpecificationId: number;
    @Type(() => StudySpecification)
    studySpecification: StudySpecification;
}
type TUpdateStudySpecificationModuleOrder = Partial<Omit<StudySpecificationModule, 'studyModuleSpecificationId'| 'studyModuleSpecification'| 'studySpecificationId'| 'studySpecification' >>;
export class UpdateStudySpecificationModuleOrder extends autoImplement<TUpdateStudySpecificationModuleOrder>() {
}
type TUpdateStudySpecificationModule = Partial<Omit<StudySpecificationModule, 'id'| 'studyModuleSpecificationId'| 'studyModuleSpecification'| 'studySpecificationId'| 'studySpecification' >>;
export class UpdateStudySpecificationModule extends autoImplement<TUpdateStudySpecificationModule>() {
}
type TCreateStudySpecification = Omit<StudySpecification, 'id'| 'study'>;
export class CreateStudySpecification extends autoImplement<TCreateStudySpecification>() {
    studyId?: number;
    existingId?: number;
}
export class StudySpecification {
    id: number;
    @Type(() => Date)
    start: Date;
    @Type(() => Date)
    end: Date;
    @Type(() => Study)
    study: Study;
    status: StudyStatus;
    type: StudyType;
    description: string;
    @Type(() => StudyModuleSpecification)
    studyModuleSpecifications: StudyModuleSpecification[];
    @Type(() => StudySpecificationComponentMeta)
    studySpecificationComponentMetas: StudySpecificationComponentMeta[];
    @Type(() => StudySpecificationModule)
    studySpecificationModules: StudySpecificationModule[];
    requiredBotHours: number;
    requiredBpvHours: number;
    requiredSbuHours: number;
    requiredBotHoursToGo: number;
    code: string;
    cohort: string;
    hasProgression: boolean;
}
type TUpdateStudySpecification = Partial<Omit<StudySpecification, 'id'>>;
export class UpdateStudySpecification extends autoImplement<TUpdateStudySpecification>() {
}
type TCreateStudyStopReason = Omit<StudyStopReason, 'id' >;
export class CreateStudyStopReason extends autoImplement<TCreateStudyStopReason>() {
}
export class StudyStopReason {
    id: number;
    @Type(() => ClientStudyTrack)
    clientStudyTrack: ClientStudyTrack;
    clientStudyTrackId: number;
    @Type(() => GlobalType)
    category: GlobalType;
    categoryId: number;
    @Type(() => GlobalType)
    reason: GlobalType;
    reasonId: number;
    stopExplanation: string;
    stopStatus: StudyPhase;
}
type TUpdateStudyStopReason = Partial<Omit<StudyStopReason, 'id' >>;
export class UpdateStudyStopReason extends autoImplement<TUpdateStudyStopReason>() {
}
type TCreateStudy = Omit<Study, 'id'| 'studySpecifications'>;
export class CreateStudy extends autoImplement<TCreateStudy>() {
}
export class Study {
    id: number;
    code: string;
    sbbIdentifier: string;
    name: string;
    description: string;
    @Type(() => StudySpecification)
    studySpecifications: StudySpecification[];
    type: StudyType;
    level: StudyLevel;
    intakeMandatory: boolean;
    assessmentMandatory: boolean;
    goMandatory: boolean;
    phaseDescriptions: any;
}
type TUpdateStudy = Omit<Study, 'id'| 'studySpecifications'>;
export class UpdateStudy extends autoImplement<TUpdateStudy>() {
}
type TCreateSurveyAnswer = Omit<SurveyAnswer, 'id'| 'surveyResponse'| 'surveyQuestion'| 'surveyResponseId' >;
export class CreateSurveyAnswer extends autoImplement<TCreateSurveyAnswer>() {
}
export class SurveyAnswer {
    id: number;
    @Type(() => SurveyResponse)
    surveyResponse: SurveyResponse;
    surveyResponseId: number;
    @Type(() => SurveyQuestion)
    surveyQuestion: SurveyQuestion;
    surveyQuestionId: number;
    answer: string;
}
type TUpdateSurveyAnswer = Partial<Omit<SurveyAnswer, 'id' >>;
export class UpdateSurveyAnswer extends autoImplement<TUpdateSurveyAnswer>() {
}
type TCreateSurveyNorm = Omit<SurveyNorm, 'id'| 'question' >;
export class CreateSurveyNorm extends autoImplement<TCreateSurveyNorm>() {
}
export class SurveyNorm {
    id: number;
    type: SurveyNormType;
    value: number;
    @Type(() => SurveyQuestion)
    question: SurveyQuestion;
    questionId: number;
}
type TUpdateSurveyNorm = Partial<Omit<SurveyNorm, 'id' >>;
export class UpdateSurveyNorm extends autoImplement<TUpdateSurveyNorm>() {
}
type TCreateSurveyQuestion = Omit<SurveyQuestion, 'id'| 'sectionId' >;
export class CreateSurveyQuestion extends autoImplement<TCreateSurveyQuestion>() {
    sectionId: number;
}
export class SurveyQuestion {
    id: number;
    @Type(() => SurveySection)
    section: SurveySection;
    sectionId: number;
    question: string;
    key: string;
    type: SurveyQuestionType;
    options: any; // TODO: Set correct types
    required: boolean;
    @Type(() => SurveyNorm)
    norm: SurveyNorm;
}
type TUpdateSurveyQuestion = Partial<Omit<SurveyQuestion, 'id'| 'norm' >>;
export class UpdateSurveyQuestion extends autoImplement<TUpdateSurveyQuestion>() {
}
type TCreateSurveyResponse = Omit<SurveyResponse, 'id'| 'survey'| 'answers' >;
export class CreateSurveyResponse extends autoImplement<TCreateSurveyResponse>() {
    @Type(() => CreateSurveyAnswer)
    answers: CreateSurveyAnswer[];
}
export class SurveyResponse {
    createdAt: Date;
    updatedAt: Date;
    id: number;
    @Type(() => Survey)
    survey: Survey;
    surveyId: number;
    @Type(() => SurveyAnswer)
    answers: SurveyAnswer[];
    @Type(() => InternshipCompany)
    company: InternshipCompany;
    companyId: number;
    @Type(() => InternshipCompanyContact)
    internshipCompanyContact: InternshipCompanyContact;
    internshipCompanyContactId: number;
    @Type(() => User)
    takenBy: User;
    takenById: number;
    @Type(() => Client)
    client: Client;
    clientId: number;
}
type TUpdateSurveyResponse = Partial<Omit<SurveyResponse, 'id' >>;
export class UpdateSurveyResponse extends autoImplement<TUpdateSurveyResponse>() {
}
type TCreateSurveySection = Omit<SurveySection, 'id'| 'survey' >;
export class CreateSurveySection extends autoImplement<TCreateSurveySection>() {
    surveyId: number;
}
export class SurveySection {
    id: number;
    @Type(() => Survey)
    survey: Survey;
    surveyId: number;
    title: string;
    @Type(() => SurveyQuestion)
    questions: SurveyQuestion[];
}
type TUpdateSurveySection = Partial<Omit<SurveySection, 'id' >>;
export class UpdateSurveySection extends autoImplement<TUpdateSurveySection>() {
}
type TCreateSurvey = Omit<Survey, 'id'| 'sections' >;
export class CreateSurvey extends autoImplement<TCreateSurvey>() {
    @Type(() => CreateSurveySection)
    sections: CreateSurveySection[];
}
export class Survey {
    id: number;
    identifier: string;
    @Type(() => SurveySection)
    sections: SurveySection[];
    @Type(() => SurveyResponse)
    responses: SurveyResponse[];
}
type TUpdateSurvey = Partial<Omit<Survey, 'id' >>;
export class UpdateSurvey extends autoImplement<TUpdateSurvey>() {
}
type TCreateTicket = Omit<Ticket, 'id'| 'updatedAt'| 'createdAt' >;
export class CreateTicket extends autoImplement<TCreateTicket>() {
}
export class Ticket {
    number: number;
    id: number;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => ExternalCommunication)
    externalCommunications: ExternalCommunication[];
    title: string;
    name: string;
    email: string;
    phoneNumber: string;
    @Type(() => CalendarEntry)
    appointments: CalendarEntry[];
    @Type(() => CalendarEntry)
    activeAppointment: CalendarEntry;
    @Type(() => Study)
    study: Study;
    @Type(() => Client)
    client: Client;
    @Type(() => NestedClientStudyTrack)
    clientStudyTrack: NestedClientStudyTrack;
    @Type(() => ClientInternshipRequest)
    internshipRequest: ClientInternshipRequest;
    @Type(() => AssignmentSubmission)
    assignmentSubmission: AssignmentSubmission;
    @Type(() => User)
    assignedTo: User;
    category: TicketCategory;
    status: TicketStatus;
    priority: TicketPriority;
    @Type(() => MemoEntry)
    memos: MemoEntry[];
}
type TUpdateTicket = Partial<Omit<Ticket, 'id'| 'assignedTo' >>;
export class UpdateTicket extends autoImplement<TUpdateTicket>() {
    assignedToId?: number;
}
type TCreateTimeRegistration = Omit<TimeRegistration, 'id' >;
export class CreateTimeRegistration extends autoImplement<TCreateTimeRegistration>() {
}
export class MultipleTimeRegistrations {
    @Type(() => CreateTimeRegistration)
    @Type(() => CreateTimeRegistration)
    data: CreateTimeRegistration[];
    @Type(() => DocUpload)
    @Type(() => DocUpload)
    doc: DocUpload;
}
export class TimeRegistration {
    id: number;
    @Type(() => User)
    createdBy: User;
    @Type(() => Date)
    date: Date;
    clientStudyTrackId: number;
    amount: number;
    remarks: string;
    type: TimeRegistrationType;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    externalStorageId: string;
    pendingMail: boolean;
}
type TUpdateTimeRegistration = Partial<Omit<TimeRegistration, 'id' >>;
export class UpdateTimeRegistration extends autoImplement<TUpdateTimeRegistration>() {
}
export class TokenSkillGrading {
    passed: boolean;
    remarks: string;
}
export class TokenValidationResult {
    valid: boolean;
    usernameOrEmail?: string;
}
type TCreateTopicComment = Omit<TopicComment, 'id' >;
export class CreateTopicComment extends autoImplement<TCreateTopicComment>() {
}
export class TopicComment {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => User)
    createdBy: User;
    text: string;
    topicId: number;
    deleted: boolean;
}
type TUpdateTopicComment = Partial<Omit<TopicComment, 'id' >>;
export class UpdateTopicComment extends autoImplement<TUpdateTopicComment>() {
}
type TCreateTopicLike = Omit<TopicLike, 'id' >;
export class CreateTopicLike extends autoImplement<TCreateTopicLike>() {
}
export class TopicLike {
    id: number;
    createdAt: Date;
    @Type(() => User)
    createdBy: User;
    createdById: number;
    @Type(() => Topic)
    topic: Topic;
    topicId: number;
}
type TUpdateTopicLike = Partial<Omit<TopicLike, 'id' >>;
export class UpdateTopicLike extends autoImplement<TUpdateTopicLike>() {
}
type TCreateTopic = Omit<Topic, 'id'| 'attachments' >;
export class CreateTopic extends autoImplement<TCreateTopic>() {
}
export class Topic {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    @Type(() => User)
    createdBy: User;
    title: string;
    description: string;
    @Type(() => RemoteDocument)
    attachments: RemoteDocument[];
    type: TopicType;
    categories: TopicCategory[];
    anonymous: boolean;
    @Type(() => TopicComment)
    comments: TopicComment[];
    @Type(() => TopicLike)
    likes: TopicLike[];
    status: TopicStatus;
    amountOfComments: number;
    amountOfLikes: number;
    likedByMe: boolean = undefined;
}
type TUpdateTopic = Partial<Omit<Topic, 'id'| 'attachments' >>;
export class UpdateTopic extends autoImplement<TUpdateTopic>() {
}
type TCreateUser = Omit<User, 'id'>;
export class CreateUser extends autoImplement<TCreateUser>() {
}
type TUpdateUser = Omit<User, 'id'>;
export class UpdateUser extends autoImplement<TUpdateUser>() {
}
export class User {
    id: number;
    authId: string;
    email: string;
    displayName: string;
    role: UserRole;
}
export enum StudyApplicationStatus { // TODO: Verify completeness.
    Concept = 'concept',
    Open = 'open',
    Intake = 'intake',
    Accepted = 'accepted', // Means client is accepted; waiting for official documents to start.
    Denied = 'denied',
    Started = 'started', // Means client has started a study track (after signing OOK, registering with DUO),
    WaitOnClient = 'wait_on_client'
}

export enum Gender {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

export enum PreviousEducationLevel {
    NONE = 'none',
    LBO = 'lbo',
    VBO = 'vbo',
    VMBO = 'vmbo',
    MAVO = 'mavo',
    MAVO_VBO = 'mavo_vbo',
    HAVO = 'havo',
    MULO = 'mulo',
    VWO = 'vwo',
    MBO_1 = 'mbo_1',
    MBO_2 = 'mbo_2',
    MBO_3 = 'mbo_3',
    MBO_4 = 'mbo_4',
    HBO = 'hbo',
    WO = 'wo'
}

export enum StudyApplicationSource {
    Website = 'website',
    Manual = 'manual',
    Other = 'other'
}

export enum PaymentBy {
    Self = 'self',
    Company = 'company',
    Undetermined = 'undetermined'
}

export enum BillingDetailsType {
    Individual = 'individual',
    Company = 'company'
}

export enum PaymentStatus {
    Sent = 'sent',
    Paid = 'paid',
    Expired = 'expired',
    Cancelled = 'cancelled'
}

export enum ClientStatus {
    Active = 'active',
    Inactive = 'inactive'
}

export enum StudyStatus {
    Active = 'active',
    Cancelled = 'cancelled',
    Completed = 'completed'
}

export enum StudyType {
    Course = 'course',
    Education = 'education',
    ChoiceCourse = 'choiceCourse'
}

export enum StudyLevel {
    Course = 'course',
    ChoiceCourse = 'choice',
    MBO_1 = 'mbo1',
    MBO_2 = 'mbo2',
    MBO_3 = 'mbo3',
    MBO_4 = 'mbo4',
    HBO_5 = 'hbo5'
}

export enum RemoteDocType {
    Diploma = 'diploma',
    Text = 'text',
    Image = 'image',
    OOK = 'ook',
    POK = 'pok',
    Video = 'video'
}

export enum RemoteDocProtocol {
    Local = 'local',
    GoogleDrive = 'google-drive',
    OneDrive = 'one-drive'
}

export enum IntakeGradingResult {
    Accepted = 'accepted',
    Denied = 'denied'
}

export enum IntakeHealthcareLevel {
    BelowAverage = 'below_average',
    Average = 'average',
    Good = 'good'
}

export enum ForeignDiploma {
    No = 'no',
    Recognised = 'recognized',
    NonRecognised = 'non_recognized'
}

export enum StudyPhase {
    Awaiting = 'awaiting',
    Development = 'development',
    Exams = 'exams',
    Cancelled = 'cancelled',
    Certified = 'certified',
    Migrated = 'migrated'
}

export enum CertificationPhase {
    None = 'none',
    Open = 'open',
    CheckReport = 'check_report',
    ReportAccepted = 'report_accepted',
    AwaitingResponse = 'awaiting_response',
    Invite = 'invite',
    Assessment = 'assessment',
    ExamsCompleted = 'exams_complete',
    RetryReport = 'retry_report',
    AwaitingProduct = 'awaiting_product',
    RetryAssessment = 'retry_assessment',
    AssessmentCompleted = 'assessment_completed',
    DiplomaCheck = 'diploma_check',
    DiplomaGrading = 'diploma_grading',
    DiplomaCheckNegative = 'diploma_check_negative',
    DiplomaRefused = 'diploma_refused',
    DiplomaGranted = 'diploma_granted',
    Certified = 'certified'
}

export enum GoStatus {
    None = 'none',
    Requested = 'requested',
    Accepted = 'accepted',
    Denied = 'denied'
}

export enum StudyComponentType {
    Test = 'test',
    Internship = 'internship',
    Exam = 'exam',
    Diploma = 'diploma',
    Generic = 'generic',
    Go = 'go',
    Assessment = 'assessment',
    Migrated = 'migrated',
    PracticalExam = 'practical_exam',
    TheoreticalExam = 'theoretical_exam',
    Choice = 'choice',
    ChoiceExam = 'choice_exam',
    Course = 'course',
    Skill = 'skill',
    Quiz = 'quiz',
    CareupProtocol = 'careup_protocol' // TODO: maybe rename this to virtual skill?
}

export enum StudyResultType {
    Number = 'numeric',
    Grade = 'grade',
    NoShow = 'no_show',
    ThreePointScale = 'three_point_scale',
    TwoPointScale = 'two_point_scale',
    Exempt = 'exempt'
}

export enum AssignmentType {
    Mandatory = 'mandatory',
    Voluntary = 'voluntary',
    Administrative = 'administrative'
}

export enum ActivityType {
    SkillsLab = 'skills_lab',
    Course = 'course',
    Lesson = 'lesson'
}

export enum ActivityStatus {
    Open = 'open',
    Closed = 'closed',
    Submitted = 'submitted'
}

export enum MemoType {
    Note = 'note',
    Assessment = 'assessment',
    Certification = 'certification',
    Warning = 'warning',
    Activity = 'activity'
}

export enum CalendarEntryType {
    Appointment = 'appointment',
    Lesson = 'lesson',
    Exam = 'exam',
    Test = 'test',
    Intake = 'intake',
    IntakePlannable = 'intake_plannable',
    Call = 'call',
    Go = 'go'
}

export enum TopicType {
    Suggestion = 'suggestion',
    News = 'news'
}

export enum TopicCategory {
    Facility = 'facility',
    Exams = 'examens',
    Internship = 'internship',
    Graduation = 'graduation',
    Parking = 'parking',
    Timetable = 'timetable',
    Teachers = 'teachers',
    MijnMedivus = 'mijn_medivus',
    Medicatief = 'medicatief',
    Other = 'other'
}

export enum TopicStatus {
    Open = 'open',
    Closed = 'closed'
}

export enum ExternalCommunicationProtocol {
    Email = 'email',
    Letter = 'letter',
    Phone = 'phone',
    SMS = 'sms'
}

export enum ExternalCommunicationType {
    Generic = 'generic',
    OOK = 'ook',
    POK = 'pok',
    Ticket = 'ticket'
}

export enum ExternalCommunicationDirection {
    In = 'in',
    Out = 'out'
}

export enum ExternalCommunicationStatus {
    Created = 'created',
    Sent = 'sent',
    Delivered = 'delivered',
    Failed = 'failed',
    Rejected = 'rejected',
    Cancelled = 'cancelled'
}

export enum CertificationDecision {
    Undecided = 'undecided',
    Accepted = 'accepted',
    Denied = 'denied'
}

export enum ClientInternshipStatus {
    None = 'none',
    Active = 'active',
    Inactive = 'inactive'
}

export enum TicketStatus {
    Open = 'none',
    Processing = 'processing',
    Done = 'done',
    Archived = 'archived'
}

export enum TicketPriority {
    None = 'none',
    Low = 'low',
    Normal = 'normal',
    High = 'high',
    Urgent = 'urgent'
}

export enum TicketCategory {
    Generic = 'generic',
    StartStudy = 'start_study',
    GoRequest = 'go_request',
    InternshipRequest = 'internship_request',
    Assignment = 'assignment',
    Skill = 'skill',
}

export enum StudyModuleType {
    Generic = 'generic',
    TestModule = 'test_module',
    ExamModule = 'exam_module',
    InternshipModule = 'internship_module',
    CourseModule = 'course_module',
    ChoiceCourseModule = 'choice_course_module',
    SkillModule = 'skill_module',
    VirtualSkillModule = 'virtual_skill_module',
}

export enum ClientInternshipRequestStatus {
    Requested = 'requested',
    WaitingOnPok = 'waiting_on_pok',
    Accepted = 'accepted',
    Denied = 'denied'
}

// TODO: This is a temporary enum, until StudyComponents have been fully implemented.
export enum ChoiceCoursePackage {
    Package_1 = 'package_1',
    Package_2 = 'package_2',
    Package_3 = 'package_3',
    Package_4 = 'package_4',
    Package_5 = 'package_5'
}

export enum UserRole {
    Generic = 'generic',
    Student = 'student',
    FrontOffice = 'front_office',
    Planner = 'planner',
    Teacher = 'teacher',
    StudyCoach = 'study_coach',
    ExamOffice = 'exam_office',
    Assessor = 'assessor',
    Admin = 'admin',
    ExamCommission = 'exam_commission'
}

export enum DateSearchType {
    BirthDate = 'birthdate',
    ApplicationDate = 'application_date',
    IntakeDate = 'intake_date'
}

export enum StudySpecificationComponentMetaType {
    StudySpecificationMeta = 'study_specification_meta', // for StudySpecification <> StudyModuleSpecification pairs
    StudyModuleSpecificationMeta = 'study_module_specification_meta', // for StudyComponent <> StudyModuleSpecification pairs
    StudyComponentMeta = 'study_component_meta', // for StudyComponent <> StudyModuleSpecification <> StudySpecification pairs
    GenericComponentMeta = 'generic_component_meta' // for old results OR StudyComponent only results (such as StudyComponents without modules).
}

export enum PassingConditionType {
    PassingConditionSum = 'sum',
    PassingConditionAverage = 'average',
    PassingConditionMinimalGrade = 'minimal-grade',
}

export enum LessonDocType {
    Generic = 'generic',
    Reader = 'reader',
    Presentation = 'presentation'
}

export enum SearchResultType {
    Client = 'client',
    Application = 'application',
    Intake = 'intake',
    Prospect = 'prospect',
    Assessment = 'assessment',
    DiplomaDecision = 'diploma_decision',
    DiplomaDistribution = 'diploma_distribution',
}

export enum SurveyQuestionType {
    Text = 'text',
    YesNo = 'yes_no',
    SingleSelect = 'single_select',
    SingleCheckbox = 'single_checkbox',
    MultiCheckbox = 'multi_checkbox',
    DatePicker = 'datepicker',
    OneToTenSelect = 'one_to_ten_select',
    RadioButton = 'radio_button'
}

export enum TimeRegistrationType {
    BPV = 'bpv',
    BOT = 'bot',
    Generic = 'generic'
}

// TODO: Place these types of classes somewhere else.
export class GMailLabel {
    id: string;

    name: string;

    type: string;

    constructor(args?: Partial<GMailLabel>) {
        Object.assign(this, args);
    }
}

export enum CompanyRegion {
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd',
    E = 'e',
    F = 'f',
    G = 'g',
    H = 'h',
    I = 'i'
}

export enum CompanyLabel {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    L = 'L',
}

export enum InternshipCompanyContactType {
    ContactPerson = 'contact',
    Assessor = 'assessor',
    Other = 'other',
    Mentor = 'mentor'
}

export enum ExportType {
    Legacy = 'legacy',
    New = 'new',
}

export enum ExportFileType {
    CSV = 'csv',
    Excel = 'excel',
}

export enum ClientExportExtraField {
    Study = 'opleiding',
    StartDate = 'startdatum',
    EndDate = 'einddatum',
    Status = 'status',
    Internship = 'stage',
    Class = 'klas',
    Cohort = 'cohort',
    Result = 'resultaat',
    HealthcareLevel = 'zorgervaring',
    LanguageHelpNeeded = 'Nederlands aandachtspunt',
}

export enum SurveyIdentifiers {
    // to support production data.
    IntakeSurveyOld = 'intake',
    BpvVisitSurveyOld = 'bpv_visit_old',
    // --
    IntakeSurvey = 'after_intake',
    BpvVisitSurvey = 'bpv_visit',
    HalfwaySurvey = 'halfway_student',
    AfterTwoMonthsStudentSurvey = 'after_two_months_student',
    AfterTwoMonthsCompanySurvey = 'after_two_months_company',
    AssessmentSurvey = 'at_assessment',
    CertificateSurvey = 'after_certificate'
}

export enum GlobalTypeTaxonomy {
    InternshipCompanyType = 'internship_company_type',
    InternshipCompanyContactType = 'internship_company_contact_type',
    InternshipType = 'internship_type',
    StopCategory = 'stop_category',
    StopReason = 'stop_reason'
}

export enum SurveyNormType {
    AverageNumber = 'average_number',
    PercentageYes = 'percentage_yes',
    PercentageNo = 'percentage_no'
}

export enum StopReasonType {
    PersonalFactorsWithNoInfluence = "personal_factors_with_no_influence",
    PersonalFactorsWithInfluence = "personal_factors_with_influence",
    InstitutionalFactors = "institutional_factors",
    StudyAndCareerRelatedFactors = "study_and_career_related_factors",
    LaborAndEnvironmentalFactors = "labor_and_environmental_factors",
    WithoutDiploma = "without_diploma",
    Unknown = "unknown",
    FactorsRelatedToCrebo = "factors_related_to_crebo",
    PaymentObligationNotMet = "payment_obligation_not_met",
    Certified = "certified",
}

export enum QuizQuestionType {
    MultipleChoice = 'multiple-choice'
}

export enum FilterModulesBy {
    Modules = 'sm_type',
    Components = 'comp_type'
}

export enum TokenPurpose {
    Onboarding = 'onboarding',
    SkillGrading = 'skill_grading'
}

export enum CronJobType {
    RemoveCareUpAccount = 'remove_care_up_account',
}