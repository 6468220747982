import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import * as moment from "moment";
moment.locale('nl');
import {map, tap} from 'rxjs/operators';
import {CreateStudy, PaginatedResult, Study, StudyApplication, User} from "../../../models";
import {plainToClass} from "class-transformer";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private me: User;

  constructor(private http: HttpClient) {

  }

  get(id: number): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
  }

  whoAmI(): Observable<User> {
    if(!this.me) {
      return this.http.get<User>(`${environment.apiUrl}/user/me`).pipe(map(data => {
        this.me = data;
        return data;
      }));
    }

    return of(this.me);
  }

  getAll(pageIndex = 1, pageSize = 10,
         role?: string[]): Observable<PaginatedResult<User>> {

    if (!role || role.length === 0) {
      role = undefined;
    }

    const filter = {
      role,
    }

    return this.http.get<PaginatedResult<User>>(`${environment.apiUrl}/user?page=${pageIndex}&limit=${pageSize}&filter=${JSON.stringify(filter)}`).pipe((map(response => {
      response.items = response.items.map(plain => plainToClass(User, plain));
      return response;
    })));
  }
}
