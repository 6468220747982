import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {StudentService} from '../../core/services/medivus/student/student.service';
import {Client, User, UserRole} from '../../core/models';
import {UserService} from '../../core/services/medivus/user/study.service';
import {MatDialog} from '@angular/material/dialog';
import {DashboardService} from '../../core/services/medivus/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DashboardComponent implements OnInit {
  public client: Client;
  public user: User;
  public isAdmin = false;

  constructor(
    private studentService: StudentService,
    private dialog: MatDialog,
    public dashboardService: DashboardService,
    private userService: UserService
  ) {}

  async loadClient(): Promise<void> {
    this.user = await this.userService.whoAmI().toPromise();
    this.isAdmin = this.user.role === UserRole.Admin;
    this.client = await this.studentService.getClient().toPromise();
  }

  public async ngOnInit(): Promise<void> {
    await this.loadClient();
  }
}
