import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModuleRoutes } from './dashboard.routes';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DashboardComponent } from './dashboard.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {RoosterChoiceDialogModule} from '../shared/dialogs/rooster-choice-dialog/rooster-choice-dialog.module';
import {CareUpDialogModule} from '../shared/dialogs/care-up-dialog/care-up-dialog.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(ModuleRoutes),
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    ScrollingModule,
    MatFormFieldModule,
    RoosterChoiceDialogModule,
    CareUpDialogModule
  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerGestureConfig
  }]
})
export class DashboardModule { }
