import {Injectable} from '@angular/core';
import {IDashboardIcon} from '../../../models/_template';
import {RoosterChoiceDialogComponent} from '../../../../modules/shared/dialogs/rooster-choice-dialog/rooster-choice-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {User, UserRole} from '../../../models';
import {UserService} from '../user/study.service';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  public user: User;
  public isAdmin = false;
  public menuItems: IDashboardIcon[] = [
    {routerLink: '/mijn-gegevens', color: '#C379C9', matIcon: 'perm_identity', title: 'Mijn gegevens'},
    {color: '#81C6C6', matIcon: 'groups', title: 'Medicatief', onClick: () => {this.openExternal(environment.medicatiefUrl)}},
    {color: '#873C8A', matIcon: 'mail', title: 'Mijn outlook/e-mail', onClick: () => this.openMail()},
    {color: '#6CB8C9', matIcon: 'question_answer', title: 'Teams/Online lessen', onClick: () => {this.openExternal('https://teams.microsoft.com/')}},
    {routerLink: '/stage', color: '#E263EC', matIcon: 'work_outline', title: 'Mijn BPV locatie'},
    {color: '#B55CA3', matIcon: 'date_range', title: 'toetsrooster', onClick: () => this.openRoosterChoice()},
    {routerLink: '/voortgang', color: '#8C6E83', matIcon: 'timeline', title: 'Mijn voortgang'},
    {color: '#C379C9', matIcon: 'calendar_today', title: 'Lesrooster', onClick: () => {this.openExternal('https://medivus.nl/lesrooster')}},
    {color: '#66ADAF', matIcon: 'local_library', title: 'Digibib', onClick: () => {this.openExternal('https://digibib.nl')}},
    // {color: '#8C6E83', matIcon: 'event', title: 'remindo-planner'},
    {routerLink: '/studentenpanel', color: '#F3A33D', matIcon: 'tungsten', title: 'studentenpanel', visibleAsAdmin: true},
  ];

  constructor(private dialog: MatDialog, private userService: UserService) {
    this.userService.whoAmI().subscribe(user => {
      this.user = user;
      this.isAdmin = user.role === UserRole.Admin;

      if (this.isAdmin) {
        this.menuItems = this.menuItems.filter(el => el.visibleAsAdmin);
      }
    });
  }

  private openRoosterChoice(): void {
    this.dialog.open(RoosterChoiceDialogComponent, {
      data: {
        title: 'Maak een keuze voor het inplannen van toetsen/cursussen of examens.',
      },
    });
  }

  openMail() {
    return this.openExternal(`https://outlook.live.com/mail/?login_hint=${this.user.email}&actSwt=true`);
  }

  private openExternal(url: string): void {
    window.open(url, '_blank');
  }
}
