import {
  AuthenticationResult, EndSessionPopupRequest, EndSessionRequest,
  IPublicClientApplication, Logger,
  PopupRequest,
  RedirectRequest,
  SilentRequest, SsoSilentRequest
} from "@azure/msal-browser";
import {Observable} from "rxjs";
import {IMsalService} from "@azure/msal-angular";
import {Injectable} from "@angular/core";

@Injectable()
export class MockMsalService implements IMsalService {
  instance: IPublicClientApplication;
  constructor() {}
  acquireTokenPopup(request: PopupRequest): Observable<AuthenticationResult> { return this.logMockMessage(); }
  acquireTokenRedirect(request: RedirectRequest): Observable<void> { return this.logMockMessage(); }
  acquireTokenSilent(silentRequest: SilentRequest): Observable<AuthenticationResult> { return this.logMockMessage(); }
  handleRedirectObservable(hash?: string): Observable<AuthenticationResult> { return this.logMockMessage(); }
  loginPopup(request?: PopupRequest): Observable<AuthenticationResult> { return this.logMockMessage(); }
  initialize(): Observable<void> { return this.logMockMessage(); }
  loginRedirect(request?: RedirectRequest): Observable<void> { return this.logMockMessage(); }
  logout(logoutRequest?: EndSessionRequest): Observable<void> { return this.logMockMessage(); }
  logoutRedirect(logoutRequest?: EndSessionRequest): Observable<void> { return this.logMockMessage(); }
  logoutPopup(logoutRequest?: EndSessionPopupRequest): Observable<void> { return this.logMockMessage(); }
  ssoSilent(request: SsoSilentRequest): Observable<AuthenticationResult> { return this.logMockMessage(); }
  getLogger(): Logger { return this.logMockMessage(); }
  setLogger(logger: Logger): void { return this.logMockMessage(); }

  private logMockMessage(): any {
    console.log('Mocked service!');
    return;
  }
}
